import BackendMiniCart from 'components/BackendMiniCart'
import { useCart } from 'components/Cart'
import DeliveryEstimate from 'components/DeliveryEstimate'
import { Link } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const CartPage = () => {
  const cart = useCart()

  return (
    <>
      <GatsbySeo title="Shopping Basket" />

      <section className="section pt-1">
        <div className="container">
          <h1 className="title">Shopping Basket</h1>
          <div className="subtitle is-size-6">
            <Link to="/#quicklinks">Continue shopping</Link>
          </div>
          {cart.isEmpty && <p>Your shopping basket is empty</p>}

          {cart.cart.showWarning && (
            <article className="message is-warning">
              <div className="message-body">
                Apologies, the item you selected is out of stock at this time. Please check back later.
              </div>
            </article>
          )}

          {!cart.calculated && cart.loading && (
            <div className="btn-loading">
              <p className="loading">
                <span></span>
                <span></span>
                <span></span>
              </p>
            </div>
          )}

          {cart.calculated && (
            <>
              <BackendMiniCart
                loading={cart.loading}
                orderPriceDetails={cart.calculated.calculateOrderPrice}
                shippingMethods={cart.calculated.shippingMethods}
                discountCode={cart.cart.discountCode}
                onChangeLineQuantity={cart.changeLineQuantity}
                onSetDiscountCode={cart.setDiscountCode}
                onChangeShippingType={(shippingType) => {
                  // No-op
                }}
                onSetArrivalData={cart.setArrivalData}
                onRemoveLine={cart.removeLine}
                isBasketPage={true}
              />

              {!cart.onlySupervisions && (
                <div className="mt-2 px-3 py-2 has-background-info-light">
                  <DeliveryEstimate deliveryEstimate={cart.calculated.calculateOrderPrice.deliveryEstimate} />
                </div>
              )}
            </>
          )}

          <div className="is-flex is-justify-content-flex-end mt-3">
            <div className="has-text-right">
              {cart.checkoutError && <p className="help is-danger is-right">{cart.checkoutError}</p>}

              <Link className="button is-midnightBlue" disabled={!!cart.checkoutError} to="/checkout">
                Next step: {cart.hasSupervisions ? 'Choose a time' : 'Your Details'}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CartPage
